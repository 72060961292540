import { ModalBody } from '@common/PromiseModal';
import { scroll } from '@styles';
import styled from 'styled-components';

export const Body = styled(ModalBody)`
  width: 60vw;
  max-width: 800px;
`;

export const Grid = styled.div`
  display: flex;
  gap: 24px;
`;

export const Column = styled.div`
  flex: 1;
`;

export const OptionsContainer = styled.div`
  margin-top: 34px;
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;

  > * {
    margin-bottom: 0;
  }
  > *:nth-child(2) {
    flex: 1;
  }
`;

export const ScrollableOptions = styled.div`
  max-height: 250px;
  overflow: auto;
  ${scroll};
`;

export const OptionLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const IconButton = styled.div`
  padding: 8px;
  cursor: pointer;
`;

export const AddOptionButton = styled.div<{ withAdditionalMargin?: boolean }>`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #009688;
  cursor: pointer;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
  margin-top: ${({ withAdditionalMargin }) => (withAdditionalMargin ? '12px' : '0')};
`;

export const NewRecordLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ErrorText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #d54855;
`;
