import React, { useMemo } from 'react';
import { EntityAccessType, Property, PropertyType, RecordType } from '@types';
import { isDefaultPropertiesGroup } from '@hooks/usePropertyGroup';
import {
  CheckboxField,
  CheckboxGroupField,
  DateTimeField,
  Form,
  FormValidationRules,
  InputField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { Control } from 'react-hook-form';
import { usePrevious, useUpdateEffect } from '@react-hookz/web';
import { cloneDeep } from 'lodash';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';

import { getFullName } from '@utils/utils';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useTeams } from '@hooks/useTeams';
import { useRoles } from '@hooks/useRoles';
import { normalizeRoleName } from '@utils/roles';
import { useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ProjectColumnVisibility } from '@generated/types/graphql';
import { ModalFooter } from '@common/PromiseModal';
import { validateUrl } from '@utils/validations';
import { Body, Column, Grid } from './styled';
import { Options } from './Options';

interface Props {
  scope: RecordType;
  onClose: () => void;
  initialValues?: Property;
  initialValuesGroup?: { id: number; name: string };
  groups: { id: number; name: string }[];
}

enum PropertyTypeFrontend {
  SingleDropdown = 'singleDropdown',
  MultipleDropdown = 'multipleDropdown',
  Numeric = 'numeric',
  Date = 'date',
  DateTime = 'dateTime',
  Text = 'text',
  Person = 'person',
  File = 'file',
  Link = 'link'
}

type PropertyTypeOption = { id: PropertyTypeFrontend; type: PropertyType; label: string };

const PROPERTY_TYPE_OPTIONS: PropertyTypeOption[] = [
  { id: PropertyTypeFrontend.Text, type: PropertyType.Text, label: 'Text' },
  { id: PropertyTypeFrontend.Numeric, type: PropertyType.Numeric, label: 'Numeric' },
  { id: PropertyTypeFrontend.Date, type: PropertyType.Date, label: 'Date' },
  { id: PropertyTypeFrontend.DateTime, type: PropertyType.DateTime, label: 'Date and time' },
  { id: PropertyTypeFrontend.SingleDropdown, type: PropertyType.Dropdown, label: 'Dropdown (single)' },
  { id: PropertyTypeFrontend.MultipleDropdown, type: PropertyType.Dropdown, label: 'Dropdown (multiple)' },
  { id: PropertyTypeFrontend.Person, type: PropertyType.Person, label: 'Person' },
  { id: PropertyTypeFrontend.File, type: PropertyType.File, label: 'File' },
  { id: PropertyTypeFrontend.Link, type: PropertyType.Link, label: 'Button/Link' }
];

interface FormValues {
  name: string;
  type: PropertyTypeOption;
  defaultValue: any;
  options: { value: string }[];
  group?: { id: number; name: string };
  iconUrl: string;
  isShared?: boolean;
  scopes: RecordType[];
  access: any[];
  externalVisibility: { value: ProjectColumnVisibility; label: string };
  internalVisibility: { value: ProjectColumnVisibility; label: string };
  linkLabel: string;
}

const NEW_PROPERTY_INITIAL_VALUES: FormValues = {
  name: '',
  type: PROPERTY_TYPE_OPTIONS[0],
  defaultValue: null,
  options: [],
  iconUrl: null,
  isShared: false,
  scopes: [],
  access: [],
  externalVisibility: { value: ProjectColumnVisibility.Hidden, label: 'Hidden' },
  internalVisibility: { value: ProjectColumnVisibility.Optional, label: 'Optional' }
};

export const DefaultValueField = ({
  type,
  name,
  label,
  control,
  options
}: {
  name: any;
  label: string;
  type: PropertyTypeFrontend;
  control: Control<FormValues>;
  options: { value: string }[];
}) => {
  const { data: companyUsers } = useAllCompaniesUsers();

  switch (type) {
    case PropertyTypeFrontend.Text:
    case PropertyTypeFrontend.Link:
      return <InputField clearOnUnmount label={label} name={name} control={control} />;
    case PropertyTypeFrontend.Numeric:
      return <InputField clearOnUnmount type="number" label={label} name={name} control={control} />;
    case PropertyTypeFrontend.Date:
      return (
        <DateTimeField
          withPortal
          isOnlyDate
          isClearable
          placeholder="Select date"
          label={label}
          name={name}
          control={control}
        />
      );
    case PropertyTypeFrontend.SingleDropdown:
      return (
        <SelectField
          clearOnUnmount
          label={label}
          name={name}
          control={control}
          options={options}
          getOptionLabel={(option) => option.value}
        />
      );
    case PropertyTypeFrontend.MultipleDropdown:
      return (
        <SelectField
          clearOnUnmount
          isMulti
          label={label}
          name={name}
          control={control}
          options={options}
          getOptionLabel={(option) => option.value}
        />
      );
    case PropertyTypeFrontend.Person:
      return (
        <SelectField
          label={label}
          name={name}
          control={control}
          noOptionsText="User not found"
          options={companyUsers}
          getOptionLabel={getFullName}
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
        />
      );
    default:
      return null;
  }
};

const RECORD_TYPE_TITLE_MAP: Record<RecordType, string> = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

const SHARING_OPTIONS = [
  { value: RecordType.ACCOUNT, label: 'Client' },
  { value: RecordType.PROJECT, label: 'Project' },
  { value: RecordType.DEAL, label: 'Request' }
];

const VISIBILITY_OPTIONS = [
  { value: ProjectColumnVisibility.Required, label: 'Required' },
  { value: ProjectColumnVisibility.Optional, label: 'Optional' },
  { value: ProjectColumnVisibility.Hidden, label: 'Hidden' }
];

const mapPropertyToFormValues = (
  property: Property,
  group: { id: number; name: string },
  teams: { id: number; name: string }[],
  roles: { id: number; name: string }[]
): FormValues => {
  const type = PROPERTY_TYPE_OPTIONS.find((option) => {
    if (property.type === PropertyType.Dropdown && option.type === PropertyType.Dropdown) {
      if (property.multiple) {
        return option.id === PropertyTypeFrontend.MultipleDropdown;
      }

      return option.id === PropertyTypeFrontend.SingleDropdown;
    }

    return option.type === property.type;
  });

  const options = property.additional?.values?.map((value) => ({ value })) ?? [];

  const defaultValue = (() => {
    if (type.type === PropertyType.Dropdown) {
      if (!property.additional?.defaultValue) {
        return type.id === PropertyTypeFrontend.MultipleDropdown ? [] : null;
      }

      if (type.id === PropertyTypeFrontend.MultipleDropdown) {
        return options.filter((option) => property.additional.defaultValue.includes(option.value));
      }

      return options.find((option) => option.value === property.additional?.defaultValue);
    }

    return property.additional?.defaultValue;
  })();

  return {
    name: property.name,
    type: type.type === PropertyType.Dropdown ? type : type,
    defaultValue,
    options,
    linkLabel: property.additional?.linkLabel,
    iconUrl: property.iconUrl,
    isShared: Boolean(property.sharingType),
    scopes: property.scope,
    access: (property.access ?? []).map((access) => ({
      ...access,
      role: access.type === EntityAccessType.role ? roles.find((role) => role.id === access.roleId) : null,
      team: access.type === EntityAccessType.team ? teams.find((team) => team.id === access.teamId) : null,
      key:
        access.type === EntityAccessType.role
          ? `${EntityAccessType.role}-${access.roleId}`
          : `${EntityAccessType.team}-${access.teamId}`
    })),
    group,
    externalVisibility: VISIBILITY_OPTIONS.find((option) => option.value === property.externalVisibility),
    internalVisibility: VISIBILITY_OPTIONS.find((option) => option.value === property.internalVisibility)
  };
};

export const PropertyForm = ({ scope, initialValues, initialValuesGroup, onClose, groups }: Props) => {
  const isEdit = Boolean(initialValues);
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    create: { mutateAsync: create },
    update: { mutateAsync: update }
  } = useCompanyPropertiesMutations();
  const queryClient = useQueryClient();
  const { data: teams } = useTeams(companyId);
  const {
    rolesQuery: { data: roles }
  } = useRoles();

  const postForm = async (values: FormValues) => {
    const shouldSendGroupId = !initialValues || values.group?.id !== initialValuesGroup?.id;

    const defaultValue = (() => {
      if (values.type.type === PropertyType.Dropdown) {
        if (values.type.id === PropertyTypeFrontend.MultipleDropdown) {
          return values.defaultValue?.map((option) => option.value);
        }

        return values.defaultValue?.value ?? null;
      }

      return values.defaultValue ?? undefined;
    })();

    const dto = {
      access: values.access,
      iconUrl: values.iconUrl,
      id: initialValues?.id,
      isAdditional: true,
      isRequired: false,
      name: values.name.trim(),
      targetScope: scope,
      scope: values.scopes,
      sharingType: values.isShared ? 'family' : null,
      type: values.type.type,
      additional: {
        defaultValue,
        linkLabel: values.type.type === PropertyType.Link ? values.linkLabel : undefined,
        values:
          values.type.type === PropertyType.Dropdown
            ? values.options.map((option) => option.value.trim()).filter(Boolean)
            : undefined
      },
      multiple: values.type.id === PropertyTypeFrontend.MultipleDropdown,
      groupId: shouldSendGroupId ? values.group?.id ?? null : undefined,
      externalVisibility: values.externalVisibility.value,
      internalVisibility: values.internalVisibility.value
    };
    if (!initialValues) {
      await create(dto);
    } else {
      await update(dto);
    }

    queryClient.invalidateQueries(ReactQueryKey.CompanyProperties);

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues
      ? mapPropertyToFormValues(initialValues, initialValuesGroup, teams, roles)
      : { ...NEW_PROPERTY_INITIAL_VALUES, scopes: [scope] }
  });

  const {
    formState: { isSubmitting, errors },
    control,
    watch,
    setValue,
    getValues
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      type: {
        isRequired: true
      },
      scopes: {
        validate: (value) => {
          const { isShared } = getValues();

          if (isShared && value.length < 2) {
            return 'You must select at least 2 property scopes';
          }

          return undefined;
        }
      },
      internalVisibility: {
        isRequired: true
      },
      externalVisibility: {
        isRequired: true
      },
      defaultValue: {
        validate: (value) => {
          const { type } = getValues();

          if (type.type === PropertyType.Link) {
            return validateUrl(value);
          }

          return undefined;
        }
      }
    }),
    [getValues]
  );

  const type = watch('type');
  const options = cloneDeep(watch('options') || []);
  const isShared = watch('isShared');

  const previousType = usePrevious(type);
  const previousIsShared = usePrevious(isShared);

  useUpdateEffect(() => {
    if (type.id !== previousType.id) {
      setValue('defaultValue', null);
    }
  }, [type, previousType, setValue]);

  useUpdateEffect(() => {
    if (isShared !== previousIsShared) {
      setValue('scopes', [scope]);
    }
  }, [isShared, previousIsShared, setValue]);

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => !isDefaultPropertiesGroup(group));
  }, [groups]);

  const sharingOptions = useMemo(() => {
    return SHARING_OPTIONS.map((option) => ({
      ...option,
      isDisabled: option.value === scope
    }));
  }, [scope]);

  const accessOptions = useMemo(() => {
    return [
      ...(teams || []).map((team) => ({
        type: EntityAccessType.team,
        teamId: team.id,
        team: {
          ...team,
          users: team.teamUsers
        },
        key: `${EntityAccessType.team}-${team.id}`
      })),
      ...(roles || []).map((role) => ({
        type: EntityAccessType.role,
        roleId: role.id,
        role,
        key: `${EntityAccessType.role}-${role.id}`
      }))
    ];
  }, [teams, roles]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <Grid>
          <Column>
            <InputField name="name" label="Property name" control={control} />

            <DefaultValueField
              type={type.id}
              options={options}
              name="defaultValue"
              label="Default value"
              control={control}
            />

            <SelectField
              label="Group"
              name="group"
              control={control}
              options={filteredGroups}
              getOptionLabel={(option) => option.name}
            />

            <InputField name="iconUrl" label="Icon URL" control={control} />

            <CheckboxField
              label="Sharing"
              variant="switch"
              name="isShared"
              control={control}
              description={isShared ? 'At least 2 property scopes must be selected' : undefined}
            />

            {isShared && <CheckboxGroupField label="" name="scopes" control={control} options={sharingOptions} />}
          </Column>
          <Column>
            <SelectField
              disabled={isEdit}
              name="type"
              control={control}
              label="Property type"
              isClearable={false}
              options={PROPERTY_TYPE_OPTIONS}
              getOptionLabel={(option) => option.label}
            />

            {type.type === PropertyType.Link && <InputField name="linkLabel" label="Button label" control={control} />}

            {type.type === PropertyType.Dropdown && <Options control={control} error={errors.options?.root?.message} />}

            <SelectField
              name="access"
              control={control}
              label="Access"
              options={accessOptions}
              isMulti
              getOptionLabel={(option) => (option.role ? normalizeRoleName(option.role.name) : option.team.name)}
              groupBy={(option) => {
                if ('role' in option) {
                  return 'Roles';
                }
                if ('team' in option) {
                  return 'Teams';
                }

                return null;
              }}
            />

            <SelectField
              name="internalVisibility"
              control={control}
              label={`New Internal ${RECORD_TYPE_TITLE_MAP[scope]}`}
              options={VISIBILITY_OPTIONS}
              getOptionLabel={(option) => option.label}
              isClearable={false}
            />

            {scope === RecordType.DEAL && (
              <SelectField
                name="externalVisibility"
                control={control}
                label={`New External ${RECORD_TYPE_TITLE_MAP[scope]}`}
                options={VISIBILITY_OPTIONS}
                getOptionLabel={(option) => option.label}
                isClearable={false}
                disabled={type.type === PropertyType.File || type.type === PropertyType.Person}
              />
            )}
          </Column>
        </Grid>
      </Body>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {initialValues ? 'Update' : 'Create'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
